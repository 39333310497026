// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-community-js": () => import("./../../../src/pages/community.js" /* webpackChunkName: "component---src-pages-community-js" */),
  "component---src-pages-faq-faq-1-js": () => import("./../../../src/pages/faq/faq-1.js" /* webpackChunkName: "component---src-pages-faq-faq-1-js" */),
  "component---src-pages-faq-faq-10-js": () => import("./../../../src/pages/faq/faq-10.js" /* webpackChunkName: "component---src-pages-faq-faq-10-js" */),
  "component---src-pages-faq-faq-2-js": () => import("./../../../src/pages/faq/faq-2.js" /* webpackChunkName: "component---src-pages-faq-faq-2-js" */),
  "component---src-pages-faq-faq-3-js": () => import("./../../../src/pages/faq/faq-3.js" /* webpackChunkName: "component---src-pages-faq-faq-3-js" */),
  "component---src-pages-faq-faq-4-js": () => import("./../../../src/pages/faq/faq-4.js" /* webpackChunkName: "component---src-pages-faq-faq-4-js" */),
  "component---src-pages-faq-faq-5-js": () => import("./../../../src/pages/faq/faq-5.js" /* webpackChunkName: "component---src-pages-faq-faq-5-js" */),
  "component---src-pages-faq-faq-6-js": () => import("./../../../src/pages/faq/faq-6.js" /* webpackChunkName: "component---src-pages-faq-faq-6-js" */),
  "component---src-pages-faq-faq-7-js": () => import("./../../../src/pages/faq/faq-7.js" /* webpackChunkName: "component---src-pages-faq-faq-7-js" */),
  "component---src-pages-faq-faq-8-js": () => import("./../../../src/pages/faq/faq-8.js" /* webpackChunkName: "component---src-pages-faq-faq-8-js" */),
  "component---src-pages-faq-faq-9-js": () => import("./../../../src/pages/faq/faq-9.js" /* webpackChunkName: "component---src-pages-faq-faq-9-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-find-a-dentist-js": () => import("./../../../src/pages/find-a-dentist.js" /* webpackChunkName: "component---src-pages-find-a-dentist-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-procedure-js": () => import("./../../../src/pages/procedure.js" /* webpackChunkName: "component---src-pages-procedure-js" */),
  "component---src-pages-results-js": () => import("./../../../src/pages/results.js" /* webpackChunkName: "component---src-pages-results-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

